import React from "react"
import LayoutDefault from "../components/layouts/default"

import Contact from "../components/parts/body/contact"


export default () => (

  <LayoutDefault>
    <Contact></Contact>
  </LayoutDefault>

)
